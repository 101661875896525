import { db } from '../../firebaseFile';
import { doc, setDoc } from 'firebase/firestore';
import styles from './styles.module.scss';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
} from 'firebase/auth';
import { useTranslation } from 'react-i18next';
const Admin = () => {
  const { t } = useTranslation();
  const [state, setState] = useState({
    email: null,
    password: null,
    choose: null,
    key: null,
    uid: null,
  });
  const [advertisement, setAdvertisement] = useState({
    company: null,
    job: null,
    location: null,
    place: null,
    companyT: null,
    jobT: null,
    locationT: null,
    placeT: null,
  });
  const handleEmail = (e) => {
    setState((prev) => ({ ...prev, email: e.target.value }));
  };
  const handlePassword = (e) => {
    setState((prev) => ({ ...prev, password: e.target.value }));
  };
  const handleChoose = (e) => {
    setState((prev) => ({ ...prev, choose: e }));
  };
  const handleKey = (e) => {
    setState((prev) => ({ ...prev, key: e.target.value }));
  };
  const navigate = useNavigate();
  const handleNavigate = () => {
    state.choose === null
      ? navigate(-1)
      : setState((prev) => ({ ...prev, choose: null }));
  };

  const auth = getAuth();

  const handleSignup = () => {
    state.key === 'KlinikyaGod1'
      ? createUserWithEmailAndPassword(auth, state.email, state.password)
          .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            // ...
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage);
            // ..
          })
      : alert(t('access'));
  };
  const handleLogin = () => {
    signInWithEmailAndPassword(auth, state.email, state.password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
      });
  };
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setState((prev) => ({ ...prev, uid: user.uid }));
      } else {
        // User is signed out
        // ...
      }
    });
  }, [state.uid]);

  const handleAdd = async () => {
    const randomId = Math.random().toString(36).substring(2, 8);
    await setDoc(doc(db, 'jobs', randomId), {
      company: advertisement.company,
      time: moment().format('DD MM YYYY'),
      location: advertisement.location,
      job: advertisement.job,
      place: advertisement.place,
      companyT: advertisement.companyT,
      locationT: advertisement.locationT,
      jobT: advertisement.jobT,
      placeT: advertisement.placeT,
    }).then(() => {
      alert('1');
      window.location.reload();
    });
  };
  const handlePlace = (e) => {
    setAdvertisement((prev) => ({ ...prev, place: e.target.value }));
    console.log(e.target.value);
  };
  const handleCompany = (e) => {
    setAdvertisement((prev) => ({ ...prev, company: e.target.value }));
  };
  const handleJob = (e) => {
    setAdvertisement((prev) => ({ ...prev, job: e.target.value }));
  };
  const handleLocation = (e) => {
    setAdvertisement((prev) => ({ ...prev, location: e.target.value }));
  };
  const handlePlaceT = (e) => {
    setAdvertisement((prev) => ({ ...prev, placeT: e.target.value }));
    console.log(e.target.value);
  };
  const handleCompanyT = (e) => {
    setAdvertisement((prev) => ({ ...prev, companyT: e.target.value }));
  };
  const handleJobT = (e) => {
    setAdvertisement((prev) => ({ ...prev, jobT: e.target.value }));
  };
  const handleLocationT = (e) => {
    setAdvertisement((prev) => ({ ...prev, locationT: e.target.value }));
  };
  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div className={styles.wrapper}>
      <img
        src="/icons/backward-solid.svg"
        alt="back"
        className={styles.icon}
        onClick={handleNavigate}
      />
      <img
        src="/icons/logout.svg"
        alt="logout"
        className={styles.logout}
        onClick={handleLogout}
      />
      <div className={styles.container}>
        {state.choose === null && state.uid === null && (
          <section className={styles.buttonWrapper}>
            <button
              onClick={() => handleChoose('signup')}
              className={styles.button}
            >
              Sign Up
            </button>
            <button
              onClick={() => handleChoose('login')}
              className={styles.button}
            >
              Login
            </button>
          </section>
        )}
        {(state.choose === 'signup' || state.choose === 'login') &&
          state.uid === null && (
            <section className={styles.box}>
              <input
                type="email"
                onChange={handleEmail}
                placeholder="Your Email"
              />
              <input
                type="password"
                onChange={handlePassword}
                placeholder="Your Password"
              />
              {state.choose === 'signup' && (
                <input type="password" onChange={handleKey} placeholder="Key" />
              )}
              <button
                onClick={state.choose === 'signup' ? handleSignup : handleLogin}
                className={styles.button}
              >
                {state.choose === 'signup' ? 'Sign Up' : 'Login'}
              </button>
            </section>
          )}
        {state.uid !== null && (
          <div className={styles.workContainer}>
            <div className={styles.workWrapper}>
              <div className={styles.jobWrapper}>
                <div className={styles.text}>{t('english')}</div>
                <input
                  type="text"
                  placeholder={t('name')}
                  onChange={(e) => handleCompany(e)}
                />
                <input
                  type="text"
                  placeholder={t('position')}
                  onChange={(e) => handleJob(e)}
                />
                <select
                  name=""
                  id=""
                  placeholder="esra"
                  onChange={(e) => handlePlace(e)}
                >
                  <option value="e" disabled selected>
                    {t('place')}
                  </option>
                  <option value="remote">{t('remote')}</option>
                  <option value="office">{t('office')}</option>
                  <option value="hybrid">{t('hybrid')}</option>
                </select>
                <input
                  type="text"
                  placeholder={t('location')}
                  onChange={(e) => handleLocation(e)}
                />
              </div>
              <div className={styles.jobWrapper}>
                <div className={styles.text}>{t('turkish')}</div>
                <input
                  type="text"
                  placeholder={t('name')}
                  onChange={(e) => handleCompanyT(e)}
                />
                <input
                  type="text"
                  placeholder={t('position')}
                  onChange={(e) => handleJobT(e)}
                />
                <select
                  name=""
                  id=""
                  placeholder="esra"
                  onChange={(e) => handlePlaceT(e)}
                >
                  <option value="e" disabled selected>
                    {t('place')}
                  </option>
                  <option value="remote">{t('remote')}</option>
                  <option value="office">{t('office')}</option>
                  <option value="hybrid">{t('hybrid')}</option>
                </select>
                <input
                  type="text"
                  placeholder={t('location')}
                  onChange={(e) => handleLocationT(e)}
                />
              </div>
            </div>

            <button className={styles.button} onClick={handleAdd}>
              {t('add')}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
export default Admin;
