import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

var aboutRef;

export function Cihad() {
  aboutRef.current?.scrollIntoView({
    behavior: 'smooth',
    block: 'end',
    inline: 'nearest',
  });
}

const SecondPage = () => {
  const { t } = useTranslation();
  aboutRef = useRef(null);

  return (
    <div ref={aboutRef} className={styles.wrapper} id="about">
      <div className={styles.rotateWrapper}>
        <p className={styles.rotate}>{t('brief')}</p>
        <div className={styles.shortLine}></div>
      </div>

      <div className={styles.wrapperLeft}>
        <h1 className="capitalize">{t('Stitle')}</h1>
        <div className={`font15 gray ${styles.normalText}`}>
          <p>{t('Stext1')}</p>
          <p>{t('Stext2')}</p>
          <p>{t('Stext3')}</p>
          <p>{t('Stext4')}</p>
        </div>
      </div>

      <div className={styles.line}>{''}</div>

      <div className={`${styles.wrapperRight} `}>
        <div className={`${styles.icon} darkGray `}>
          <img src="/icons/Strategy.svg" alt="Strategy" />
          <p>{t('SIcon1')}</p>
        </div>
        <div className={`${styles.icon} darkGray `}>
          <img src="/icons/Leader.svg" alt="Leader" />
          <p>{t('SIcon2')}</p>
        </div>
        <div className={`${styles.icon} darkGray `}>
          <img src="/icons/Cohesion.svg" alt="Cohesion" />
          <p>{t('SIcon3')}</p>
        </div>
        <div className={`${styles.icon} darkGray `}>
          <img src="/icons/branding.svg" alt="branding" />
          <p>{t('SIcon4')}</p>
        </div>
      </div>
    </div>
  );
};
export default SecondPage;
