import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
const PurpleSectionOne = () => {
  const { i18n } = useTranslation();
  return (
    <div className={styles.wrapper}>
      <img src="/icons/icon.svg" alt="bluejay" className={styles.icon} />
      {i18n?.language === 'tr' ? (
        <p className={`white2 font45 ${styles.text}`}>
          <span>Küresel işe alım</span> ve <span>yetenek yönetimi</span>
          çözümlerinde güvenilir danışmanınız
        </p>
      ) : (
        <p className={`white2 font45 ${styles.text}`}>
          Your trusted consultant in <span>global recruitment</span> and{' '}
          <span>talent management</span> solutions
        </p>
      )}
    </div>
  );
};
export default PurpleSectionOne;
