import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  tr: {
    translation: {
      name: 'Şirket İsmi',
      position: 'İş Pozisyonu',
      place: 'Çalışma Şekli',
      location: 'Lokasyon',
      add: 'Ekle',
      access:
        'Erişim Engellendi: Kayıt olmak için gerekli izne sahip değilsiniz',
      brief: 'Kısaca',
      Ftitle: 'BLUE JAY İLE GÜZEL BİR KARİYER YAKALAYIN',
      Ftext1: 'Bütün karakterler, kişiler birbirilerinden bağımsızdır.',
      Ftext2:
        'Blue Jay olarak sizlerin kendinize en uygun işi bulmanıza ve becerilerinizi en iyi biçimde değerlendirmenize yardımcı oluyoruz.',
      Stitle: 'Şirketiniz için en iyi kişileri seçmenize yardımcı oluyoruz !',
      Stext1:
        'Dünyanın her yerinden beyaz yakalı becerilerine sahip kişilerin şirketinize işe alım sürecini gerçekleştiriyoruz. Mühendisler, veri bilimcileri, yazılım geliştiricileri, mimarlar bunlardan bazıları… ',
      Stext2:
        'Türkiye, Hindistan başta olmak üzere farklı ülkelerden geliştiricilerin aranması, bulunması ve işe yerleştirilmesi konusunda uzmanlık göstermekteyiz.',
      Stext3:
        'Pakistan, Endonezya ve Filipinler’den tasarımcılar hemşireler ve tasarımcılar bulmaktayız.',
      Stext4:
        ' Ayrıca AB ülkeleri ve Kuzey Amerika’dan mimarların işe alım süreçlerini gerçekleştirmekteyiz. Blue Jay olarak sektörün en rekabetçi fiyatları ile en şeffaf biçimde işe alım süreçlerinizi tamamlıyoruz.',
      SIcon1: 'Strateji',
      SIcon2: 'Takım Liderliği',
      SIcon3: 'Ekip Uyumu',
      SIcon4: 'Marka İmajınızı Koruyoruz',
      M1text:
        'Küresel işe alım ve yetenek yönetimi çözümlerinde güvenilir danışmanınız',
      Ttitle1: 'İŞ İLANLARIMIZ',
      remote: 'Uzaktan',
      office: 'iş yerinde',
      hybrid: 'Hibrid',
      J1: 'Kıdemli Arka yüz geliştiricisi',
      J2: 'Yazılım Mühendisi',
      J3: 'Peyzaj Mimarı ',
      J4: 'Satın Alma Uzmanı',
      apply: 'Başvur',
      Ttitle2: 'NEDEN BLUE JAY İLE ÇALIŞMALISINIZ?',
      Tsubtitle1: 'Zamanında ve Etkili Çözümler Sunuyoruz',
      Ttext1:
        'Günümüzün hızına ve rekabetçi ortamında aktif bir oyuncu olarak yer almanız bizim için değerli.İhtiyaç duyduğunuz pozisyon için doğru kişileri, zamanında sizlerle buluşturuyoruz.',
      Tsubtitle2: 'İhtiyaçlarınızı Belirliyoruz',
      Ttext2:
        'Şirketiniz bünyesinde konumlandırmak üzere ihtiyaç duyduğunuz pozisyona ilişkin sayının ve niteliklerin belirlenmesinde sizlere destek sağlıyoruz. Yetenek yönetimiyle, şirketinizin ihtiyaçlarını karşılayacak planlamayı oluşturuyoruz.Böylece iş değerinizi artıran, hedeflerine ulaşmayı mümkün kılan bir stratejik insan kaynakları çözümü sunuyoruz.',
      Tsubtitle3: 'Marka İmajınızı Koruyoruz.',
      Ttext3:
        'Çağdaş ve insan odaklı çalışma prensibimiz ile işe alıma dair tüm adımlarda adaylar üzerinde keyifli bir süreç geçirdiklerine dair izlenim bırakmanın önemine gönülden inanıyoruz.İhtiyaç duyduğunuz pozisyon için uygun kişiyi sizinle buluşturduktan sonra da, görüşmüş olduğumuz adaylara mutlaka geri dönüş sağlayıp, bilgilendirmede bulunuyoruz.',
      M2text: 'İşleriniz İçin En Uygun Adayı mı Arıyorsunuz?',
      contact: 'Bize ulaşın',
      categories: 'Kategoriler',
      c1: 'Veri Bilimi',

      c2: 'Yazılım Geliştirme',

      c3: 'Mimarlık',

      c4: 'Mühendislik',

      c5: 'Sağlık',
      company: 'Şirketimiz',
      main: 'Ana Sayfa',

      aboutUs: 'Hakkımızda',

      works: 'İş İlanlarımız',

      why: 'Neden BlueJay',
      buttonText: 'Fırsatları Keşfet',
      companyT: 'Teknoloji Şirketi',
      turkey: 'Türkiye',
      hours: 'Saat Önce',
    },
  },

  en: {
    translation: {
      hybrid: 'Hybrid',
      add: 'Add',
      name: 'Company Name',
      position: 'Job Position',
      place: 'Working Method',
      location: 'Location',
      access: 'Forbidden access: You are not authorized to register',
      brief: 'Brief',
      hours: 'Hours Ago',
      Ftitle: 'Get a good career with Blue Jay',
      Ftext1: `All characters and people are different from each other.`,
      Ftext2:
        'As Blue Jay, we help you find the most suitable job for you and make the most of your skills.',
      buttonText: 'Discover',
      Stitle: 'We help you choose the best individuals for your company!',
      Stext1:
        'We carry out the recruitment process of people with white collar skills from all over the world for your company. Engineers, data scientists, software developers, architects are just some of them…',
      Stext2:
        'We specialize in searching, finding and placing developers from different countries, especially Turkey and India to work. We find designers, nurses and engineers from Pakistan, Indonesia and the Philippines.',
      Stext3:
        'We also carry out the recruitment processes of architects from EU countries and North America.',
      Stext4:
        'As Blue Jay, we complete your recruitment processes in the most transparent manner with the most competitive prices in the industry.',
      SIcon1: 'Strateji',
      SIcon2: 'Team Leadership',
      SIcon3: 'Team Cohesion',
      SIcon4: 'We Protect Your Brand Image',
      M1text:
        'Your trusted consultant in global recruitment and talent management solutions',
      Ttitle1: 'OUR JOB ADVERTS',
      remote: 'Remote',
      companyT: 'Tech Company',
      turkey: 'Turkey',
      office: 'On Site',
      J1: 'Senior Backend Developer',
      J2: 'Software engineer',
      J3: 'Landscape Architect ',
      J4: 'Procurement specialist',
      apply: 'Apply',
      Ttitle2: 'WHY SHOULD YOU WORK WITH BLUE JAY?',
      Tsubtitle1: 'We Provide Timely and Effective Solutions',
      Ttext1:
        "It is important for us that you take part as an active player in today's pace and competitive environment.We bring you the right people for the position you need, on timely manner.",
      Tsubtitle2: 'We Determine Your Needs',
      Ttext2:
        "We support you in determining the number and qualifications for the position you need within your company.With talent management, we create the planning that will meet your company's needs.Thus, we offer a strategic human resources solution that increases your businesses value and makes it possible to reach your goals.",
      Tsubtitle3: 'We Protect Your Brand Image.',
      Ttext3:
        'With our modern and human-oriented working principle, we wholeheartedly believe in the importance of leaving an impression on candidates that they have had a pleasant process at all steps regarding recruitment.After connecting you with the suitable person for the position you need, we always provide feedback and inform the other candidates we have met.',
      M2text: 'Are You Looking For The Most Suitable Candidate For Your Jobs?',
      contact: 'Contact us',
      categories: 'Categories',
      c1: 'Data Science',

      c2: 'Software development',

      c3: 'Architecture',

      c4: 'Engineering',

      c5: 'Health',
      company: 'Our company',
      main: 'Home page',

      aboutUs: 'About us',

      works: 'Our Job Postings',

      why: 'Why BlueJay',
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
