import './index.css';
import Admin from './pages/admin';
import User from './pages/User';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Routes,
  HashRouter,
} from 'react-router-dom';
function App() {
  return (
    <HashRouter>
      <div className="noWrap">
        <Routes>
          <Route path="/" element={<User />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/*" element={<User />} />
        </Routes>
      </div>
    </HashRouter>
  );
}

export default App;
