import styles from './styles.module.scss';
import Navbar from '../../components/navbar';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
const FirstPage = ({ Cihad2, handleCategory }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className={styles.wrapper}>
      <div className={styles.animationWrapper}>
        <img className={styles.AnimationDiv} src="/icons/Vector 1.svg" />
        <img className={`${styles.Animation2}`} src="/icons/Vector 1.svg" />
      </div>

      <section className={styles.zUp}>
        <Navbar Cihad2={Cihad2} handleCategory={handleCategory} />
        <div className={styles.container}>
          <div className={`white font47 textCenter ${styles.title}`}>
            {t('Ftitle')}
          </div>
          <div className={styles.line}></div>
          <div>
            <p className={`white font24 textCenter ${styles.text}`}>
              {t('Ftext1')}
            </p>
            <p className={`white font24 textCenter ${styles.text}`}>
              {t('Ftext2')}
            </p>
          </div>

          <a href="#job" className={`${styles.button} font17`}>
            {t('buttonText')}
          </a>
        </div>
      </section>
    </div>
  );
};
export default FirstPage;
