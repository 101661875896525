import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
const PurpleSectionTwo = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.wrapper}>
      <img src="/icons/icon.svg" alt="bluejay" className={styles.icon} />
      <p className={`white2 font45 ${styles.text}`}>{t('M2text')}</p>
    </div>
  );
};
export default PurpleSectionTwo;
