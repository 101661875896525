import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Cihad } from '../../pages/secondPage';
const Navbar = ({ Cihad2, handleCategory }) => {
  const { t, i18n } = useTranslation();
  const handleChangeLanguage = () => {
    i18n.language === 'tr'
      ? i18n.changeLanguage('en')
      : i18n.changeLanguage('tr');
  };
  const [lang, setLang] = useState('Tr');
  useEffect(() => {
    i18n.language === 'tr' ? setLang('En') : setLang('Tr');
  }, [i18n.language]);
  const [menu, setMenu] = useState(true);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  useEffect(() => {
    windowSize?.width < 1024 && setMenu(false);
  }, [windowSize]);

  return (
    <nav className={styles.wrapper}>
      <img src="/icons/icon.svg" alt="icon" className={styles.icon} />
      <div className={styles.menuWrapper}>
        <img
          src="/icons/menu.svg"
          alt="menu"
          onClick={() => setMenu(!menu)}
          className={styles.menu}
        />
        {menu && (
          <section className={`white ${styles.links}`}>
            <div
              onClick={() => {
                Cihad();
              }}
            >
              {t('aboutUs')}
            </div>
            <div
              onClick={() => {
                Cihad2();
              }}
            >
              {' '}
              {t('works')}
            </div>
            <div onClick={() => handleCategory()}>{t('categories')}</div>
            <p onClick={handleChangeLanguage}>{lang}</p>
          </section>
        )}
      </div>
    </nav>
  );
};
export default Navbar;
