// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyAdYfHhzLJ9T7nOFLYDamCg5mjZBJC7uf0',
  authDomain: 'blujaylandingpage.firebaseapp.com',
  projectId: 'blujaylandingpage',
  storageBucket: 'blujaylandingpage.appspot.com',
  messagingSenderId: '12740820495',
  appId: '1:12740820495:web:1b6a0ae23e5ab3c4636d7a',
  measurementId: 'G-8T62CE9HBX',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
