import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
const Footer = ({ categoryRef }) => {
  const { t } = useTranslation();
  return (
    <div ref={categoryRef} className={styles.wrapper} id="category">
      <img src="/icons/icon.svg" alt="bluejay" className={styles.icon} />
      <div className={styles.textWrapper}>
        <p className={`font17P`}>{t('categories')}</p>
        <p className={`font17L purple`}>{t('c1')}</p>
        <p className={`font17L purple`}>{t('c2')}</p>
        <p className={`font17L purple`}>{t('c3')}</p>
        <p className={`font17L purple`}>{t('c4')}</p>
        <p className={`font17L purple`}>{t('c5')}</p>
      </div>
      <div className={styles.textWrapper}>
        <p className={`font17P`}>{t('company')}</p>
        <p className={`font17L purple`}>{t('main')}</p>
        <p className={`font17L purple`}>{t('aboutUs')}</p>
        <p className={`font17L purple`}>{t('works')}</p>
        <p className={`font17L purple`}>{t('why')}</p>
      </div>
      <div className={styles.iconWrapper}>
        <div className={styles.icons}>
          <img src="/icons/mail.svg" alt="mail" />
          <a
            href="mailto:onuremre@blujayconsulting.com"
            className={`font17L purple small`}
          >
            onuremre@blujayconsulting.com
          </a>
        </div>
        <div className={styles.icons}>
          <img src="/icons/phone-solid.svg" alt="phone" />
          <a href="tel:+90 533 488 60 87" className={`font17L purple `}>
            +90 533 488 60 87
          </a>
        </div>
        <div className={styles.copyRight}>copyright 2022 bluejay</div>
      </div>
    </div>
  );
};
export default Footer;
