import FirstPage from '../firstPage';
import PurpleSectionOne from '../PurpleSectionOne';
import SecondPage from '../secondPage';
import ThirdPage from '../thirdPage';
import PurpleSectionTwo from '../PurpleSectionTwo';
import Footer from '../Footer';
import { useRef } from 'react';

const User = () => {
  const blankRef = useRef(null);
  function Cihad2() {
    blankRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'nearest',
    });
  }
  const categoryRef = useRef(null);
  function handleCategory() {
    categoryRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'nearest',
    });
  }
  return (
    <div>
      <FirstPage Cihad2={Cihad2} handleCategory={handleCategory} />
      <SecondPage />
      <PurpleSectionOne />
      <ThirdPage blankRef={blankRef} />
      <PurpleSectionTwo />
      <Footer categoryRef={categoryRef} />
    </div>
  );
};
export default User;
