import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebaseFile';
import { useEffect, useState } from 'react';
import i18n from '../../i18n';
import moment from 'moment';
const ThirdPage = ({ blankRef }) => {
  const { t } = useTranslation();
  const sendEmail = (item) => {
    window.location.href = `mailto:onuremre@blujayconsulting.com?subject=${item}`;
  };
  const [result, setResult] = useState([]);
  const getData = async () => {
    const tempary = [];
    const querySnapshot = await getDocs(collection(db, 'jobs'));
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      // result.push(doc.data())
      tempary.push(doc.data());
    });
    setResult(tempary);
  };
  useEffect(() => {
    getData();
  }, []);
  console.log(result);
  return (
    <div className={styles.wrapper} id="job">
      <section className={styles.wrapperLeft}>
        <img src="/icons/icon.svg" alt="bluejay" />
      </section>
      <div className={styles.line}>{''}</div>
      <section className={styles.wrapperRight}>
        <p className={`font34 purple ${styles.titleBig}`}>{t('Ttitle1')}</p>
        <div className={styles.boxWrapper}>
          <div ref={blankRef} className={styles.box}>
            <div className={styles.title}>
              <img src="/icons/Logo1.svg" alt="job" />
              <p className={styles.title}>{t('companyT')}</p>
            </div>

            <p className={styles.text}>{t('J1')}</p>
            <div className={styles.littleBoxWrapper}>
              <p className={styles.littleBox}>{t('remote')}</p>
              <p className={styles.littleBox}>2 {t('hours')}</p>
            </div>
            <div className={styles.locationWrapper}>
              <img src="/icons/Location.svg" alt="location" />
              <p>{t('turkey')}</p>
            </div>
            <div className={styles.button} onClick={() => sendEmail(t('J1'))}>
              {t('apply')}
            </div>
          </div>
          <div className={styles.box}>
            <div className={styles.title}>
              <img src="/icons/Logo2.svg" alt="job" />
              <p className={styles.title}>{t('companyT')}</p>
            </div>

            <p className={styles.text}>{t('J2')}</p>
            <div className={styles.littleBoxWrapper}>
              <p className={styles.littleBox}>{t('remote')}</p>
              <p className={styles.littleBox}>5 {t('hours')}</p>
            </div>
            <div className={styles.locationWrapper}>
              <img src="/icons/Location.svg" alt="location" />
              <p>{t('turkey')}</p>
            </div>
            <div className={styles.button} onClick={() => sendEmail(t('J2'))}>
              {t('apply')}
            </div>
          </div>
          <div className={styles.box}>
            <div className={styles.title}>
              <img src="/icons/Logo2.svg" alt="job" />
              <p className={styles.title}>{t('companyT')}</p>
            </div>

            <p className={styles.text}>{t('J3')}</p>
            <div className={styles.littleBoxWrapper}>
              <p className={styles.littleBox}>{t('remote')}</p>
              <p className={styles.littleBox}>1 {t('hours')}</p>
            </div>
            <div className={styles.locationWrapper}>
              <img src="/icons/Location.svg" alt="location" />
              <p>{t('turkey')}</p>
            </div>
            <div className={styles.button} onClick={() => sendEmail(t('J3'))}>
              {t('apply')}
            </div>
          </div>
          <div className={styles.box}>
            <div className={styles.title}>
              <img src="/icons/Logo1.svg" alt="job" />
              <p className={styles.title}>{t('companyT')}</p>
            </div>

            <p className={styles.text}>{t('J4')}</p>
            <div className={styles.littleBoxWrapper}>
              <p className={styles.littleBox}>{t('remote')}</p>
              <p className={styles.littleBox}>7 {t('hours')}</p>
            </div>
            <div className={styles.locationWrapper}>
              <img src="/icons/Location.svg" alt="location" />
              <p>{t('turkey')}</p>
            </div>
            <div className={styles.button} onClick={() => sendEmail(t('J4'))}>
              {t('apply')}
            </div>
          </div>
          {result?.map((item) => (
            <div className={styles.box}>
              <div className={styles.title}>
                <img src="/icons/Logo1.svg" alt="job" />
                <p className={styles.title}>
                  {i18n.language === 'tr' ? item.companyT : item.company}
                </p>
              </div>

              <p className={styles.text}>
                {i18n.language === 'tr' ? item.jobT : item.job}
              </p>
              <div className={styles.littleBoxWrapper}>
                <p className={styles.littleBox}>
                  {i18n.language === 'tr' ? item.placeT : item.place}
                </p>
                <p className={styles.littleBox}>{item?.time}</p>
              </div>
              <div className={styles.locationWrapper}>
                <img src="/icons/Location.svg" alt="location" />
                <p>{i18n.language === 'tr' ? item.locationT : item.location}</p>
              </div>
              <div
                className={styles.button}
                onClick={() =>
                  sendEmail(i18n.language === 'tr' ? item.jobT : item.job)
                }
              >
                {t('apply')}
              </div>
            </div>
          ))}
        </div>
        <div className={styles.textWrapper}>
          <h1 className={styles.titleLast}>{t('Ttitle2')}</h1>
          <div className={styles.textPart}>
            <p className={`font17P`}>{t('Tsubtitle1')}</p>
            <p className={`font17L`}>{t('Ttext1')}</p>
          </div>
          <div className={styles.textPart}>
            <p className={`font17P`}>{t('Tsubtitle2')}</p>
            <p className={`font17L`}>{t('Ttext2')}</p>
          </div>
          <div className={styles.textPart}>
            <p className={`font17P`}>{t('Tsubtitle3')}</p>
            <p className={`font17L`}>{t('Ttext3')}</p>
          </div>
        </div>
      </section>
    </div>
  );
};
export default ThirdPage;
